<template>
    <section class="px-3 ml-3">
        <navbar-admin icon="icon-archive" />
        <titulo-divisor titulo="Materiales">
            <div class="row d-middle-center">
                <div class="col auto">
                    <el-input placeholder="Buscar bodega" size="small" />
                </div>
                <div class="col-auto px-1">
                    <el-tooltip content="Dashboard" placement="bottom" effect="light" visible-arrow>
                    <button class="btn btn-square32-5d cr-pointer">
                        <i class="icon-finance text-white" @click="goDashboard()" />
                    </button>
                    </el-tooltip>
                </div>
            </div>
        </titulo-divisor>

        <div class="d-middle-center mt-4">
            <button class="btn btn-general f-15 f-600 px-4" @click="verOrdenes()" style="height: 44px;">Ver ordenes de compra</button>
        </div>

        <div class="mt-5 bg-f5 p-2 py-3">
            <div class="row">
                <div class="col-6 my-auto">
                    <div class="row">
                        <div class="col-3 my-auto">
                            <p class="f-14 f-600 text-general text-center">Nombre</p>
                        </div>
                        <div class="col-3 my-auto">
                            <p class="f-14 f-600 text-general text-center">Pais</p>
                        </div>
                        <div class="col-3 my-auto">
                            <p class="f-14 f-600 text-general text-center">Ubicación</p>
                        </div>
                        <div class="col-3 my-auto">
                            <p class="f-14 f-600 text-general text-center">Dirección</p>
                        </div>
                    </div>
                </div>
                <div class="col-6 my-auto">
                    <div class="row">
                        <div class="col-3 my-auto">
                            <p class="f-14 f-600 text-general text-center">Cantidad materiales</p>
                        </div>
                        <div class="col-3 my-auto">
                            <p class="f-14 f-600 text-general text-center">Valor materiales</p>
                        </div>
                        <div class="col-3 my-auto">
                            <p class="f-14 f-600 text-general text-center">Fecha última salida</p>
                        </div>
                        <div class="col-3 my-auto">
                            <p class="f-14 f-600 text-general text-center">Fecha última entrada</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-6 my-auto">
                    <div class="row">
                        <div class="col-3 my-auto">
                            <p class="f-14 f-600 text-muted text-center">{{ bodegaDetalle.bodega && bodegaDetalle.bodega.nombre }}</p>
                        </div>
                        <div class="col-3 my-auto">
                            <p class="f-14 text-muted text-center">{{ bodegaDetalle.bodega && bodegaDetalle.bodega.pais }}</p>
                        </div>
                        <div class="col-3 my-auto">
                            <p class="f-14 text-muted text-center">{{ bodegaDetalle.bodega && bodegaDetalle.bodega.ubicacion }}</p>
                        </div>
                        <div class="col-3 my-auto">
                            <p class="f-14 text-muted text-center">{{ bodegaDetalle.bodega && bodegaDetalle.bodega.direccion }}</p>
                        </div>
                    </div>
                </div>
                <div class="col-6 my-auto">
                    <div class="row">
                        <div class="col-3 my-auto">
                            <p class="f-14 text-muted text-center">{{ bodegaDetalle.bodega && bodegaDetalle.bodega.cantidad_materiales }}</p>
                        </div>
                        <div class="col-3 my-auto">
                            <p class="f-14 text-muted text-center">{{ formatoMoneda(bodegaDetalle.bodega && bodegaDetalle.bodega.valor_materiales) }}</p>
                        </div>
                        <div class="col-3 my-auto">
                            <p class="f-14 text-muted text-center">{{ bodegaDetalle.bodega && bodegaDetalle.bodega.fecha_ultima_entrada | helper-fecha('DD MMM YYYY') }}</p>
                        </div>
                        <div class="col-3 my-auto">
                            <p class="f-14 text-muted text-center">{{ bodegaDetalle.bodega && bodegaDetalle.bodega.fecha_ultima_salida | helper-fecha('DD MMM YYYY') }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <tabla-general :mostrarBuscador="false" alturaTabla="500px" :data="bodegaDetalle.materiales">
            <el-table-column align="center" label="Referencia" prop="referencia">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2 text-center tres-puntos">{{ scope.row.referencia }}</p>
                </template>
            </el-table-column>
            <el-table-column align="center" label="Nombre de material" prop="nombre" width="500">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2 text-center tres-puntos f-600">
                        {{ scope.row.material }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column align="center" label="Tipo" prop="tipo">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2 text-center">
                        {{ scope.row.tipo }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column align="center" label="U.M." prop="um" width="60">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2 text-center">
                        {{ scope.row.unidad_medida }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column align="center" label="Valor" prop="valor">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2 text-center">
                        {{ formatoMoneda(scope.row.valor) }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column align="center" label="Cantidad" prop="cantidad">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2 text-center">
                        {{ scope.row.cantidad }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column align="center" label="Planes de mantenimiento" prop="planes">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2 text-center">{{ scope.row.mantenimiento }}</p>
                </template>
            </el-table-column>
            <el-table-column align="center" label="Estado" width="100">
                <template slot-scope="scope">
                    <i :class="`icon-circle-fill f-20 ${scope.row.estado === 0  ? 'text-inactivo' : 'text-green'} `" />
                </template>
            </el-table-column>
        </tabla-general>
    </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    name: 'materiales',
    data() {
        return {
            dataTable: []
        }
    },
    computed: {
        ...mapGetters({
            bodegaDetalle: 'bodega/bodega/bodega'
        })
    },
    async created(){
        this.$store.dispatch('navigation/breadcumbs/getBreadcumbs', [
            'bodega.main',
            'bodega.materiales'
        ]);
        this.Action_get_bodega(this.$route.params.id_bodega)
    },
    methods: {
        ...mapActions({
            Action_get_bodega: 'bodega/bodega/Action_get_bodega'
        }),
        verOrdenes() {
            const id = this.$route.params.id_bodega
            this.$router.push({ name: 'bodega.ordenes-compra', params:{ id_bodega : id } })
        },
        goDashboard(){
            this.$router.push({ name: 'bodega.dashboard'})
        },
    },
}
</script>

<style lang="scss" scoped>
.header-table{
    border: 1px solid #DDDDDD;
    border-radius: 8px 8px 0px 0px;
    border-bottom: 2px solid #0B56A7;
}
.text-inactivo{
    color: #E14A1B;
}
</style>